<template>
  <div>
    <portal to="header-toolbar">
      <a-select
        v-if="getMemberStores && getMemberStores.length > 0"
        :value="currentStoreId ? currentStoreId : getMemberStores[0].name"
        style="width: 200px; margin-right: 8px;"
        @select="memberStoreSelected"
      >
        <a-select-option
          v-for="store in getMemberStores"
          :key="store.id"
          :value="store.id"
        >
          {{ store.name }}
        </a-select-option>
      </a-select>
      <button
        class="header-button-add"
        v-show="getMemberStores && getMemberStores.length > 0"
        @click="openForm"
      >
        <a-icon type="plus" class="icon" />
        Tambah Stok
      </button>
    </portal>
    <dashboard-table
      searchPlaceholder="Cari Varian"
      :columnTable="columnsStoreVariants"
      :dataTable="getVariantsStoreComputed"
      :pagination="pagination"
      :loadingTable="getUIFlags.isFetching"
      :onSearchTable="onSearch"
      :perPageValue="perPageValue"
      :hideFilter="true"
      :actionAllUser="true"
      @handleTableChange="handleTableChange"
      @handlePerPageChange="handlePerPageChange"
    >
      <template slot="actionDropdown" slot-scope="{ record }">
        <a-menu-item>
          <a @click="handleUpdate(record, record.id)">Perbarui Stok</a>
        </a-menu-item>
      </template>
    </dashboard-table>
    <stock-product-modal
      :modal-title="modalTitle"
      ref="form"
      @fetchData="fetchData"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { Portal } from "portal-vue";
import { mapGetters, mapActions } from "vuex";
import DashboardTable from "@/components/table/DashboardTable.vue";
import StockProductModal from "@/components/stockProduct/StockProductModal.vue";

const columnsStoreVariants = [
  {
    index: 0,
    title: "No",
    dataIndex: "index",
    key: "index",
    width: 50,
  },
  {
    index: 1,
    title: "Nama Varian",
    dataIndex: "variantName",
    key: "variantName",
    sorter: true,
  },
  {
    index: 2,
    title: "SKU",
    dataIndex: "sku",
    key: "sku",
    sorter: false,
  },
  {
    index: 3,
    title: "Harga",
    dataIndex: "price",
    key: "price",
    sorter: false,
  },
  {
    index: 4,
    title: "Jumlah Stok",
    dataIndex: "quantity",
    key: "quantity",
    sorter: false,
  },
  {
    index: 5,
    title: "Terakhir Diperbaharui",
    dataIndex: "updated_at",
    key: "updated_at",
    sorter: false,
  },
  {
    index: 6,
    title: "",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 100,
    align: "center",
  },
];

export default Vue.extend({
  data() {
    return {
      isFirst: true,
      columnsStoreVariants,
      pagination: {
        page: 1,
        perPage: 10,
        current: 1,
        total: 0,
        pageSize: 0,
      },
      perPageValue: 10,
      sorter: "",
      modalTitle: "",
      currentStoreId: "",
    };
  },
  components: {
    Portal,
    DashboardTable,
    StockProductModal,
  },
  computed: {
    ...mapGetters("StockProductStore", ["getMemberStores", "getVariants", "getUIFlags"]),
    getVariantsStoreComputed() {
      const variantData = this.getVariants.map((variant, index) => {
        const tmp = { ...variant };
        tmp.index = this.pagination.pageSize * (this.pagination.current - 1) + index + 1;
        tmp.variantName = tmp.variant.name;
        tmp.sku = tmp.variant.sku;
        return tmp;
      });
      return this.isFirst ? [] : variantData;
    },
  },
  methods: {
    ...mapActions("StockProductStore", ["fetchStoresVariants", "fetchStores", "fetchVariantsStore"]),
    openForm() {
      this.modalTitle = "Tambah Stok";
      (this.$refs.form).showModal(this.currentStoreId);
    },
    handleUpdate(record) {
      this.$router.push({
        name: "Update Stock",
        params: {
          storeId: record.store_id,
          variantId: record.variant_id,
          name: record.variantName,
        },
      });
    },
    memberStoreSelected(value) {
      this.currentStoreId = value;
      // localStorage.setItem("lastStoreId", value);
      this.fetchData();
    },
    onSearch(value) {
      this.search = value;
      this.pagination.page = 1;
      this.fetchData();
    },
    handlePerPageChange(value) {
      this.perPageValue = Number(value);
      const pager = { ...this.pagination };
      pager.perpage = Number(value);
      this.pagination = pager;
      if (pager.perpage > pager.total) {
        this.pagination.page = 1;
      }
      this.fetchData();
    },
    handleTableChange({ pagination, sorter }) {
      this.sorter = "";
      if (sorter.order) {
        if (sorter.columnKey === "variantName") {
          this.sorter = [{ column: "VARIANT_NAME", order: sorter.order === "ascend" ? "ASC" : "DESC" }];
        }
      }

      const pager = { ...this.pagination };
      pager.page = pagination.current;
      pager.perpage = pagination.pageSize;
      this.pagination = pager;
      this.pagination.perpage = pagination.pageSize;
      this.pagination.page = pagination.current;
      this.fetchData();
    },
    fetchData() {
      const orderBy = this.sorter ? this.sorter : [{ column: "UPDATED_AT", order: "DESC" }];
      const options = {
        store_id: this.currentStoreId,
        orderBy,
        searchKeyword: this.search || null,
        first: this.pagination.perpage,
        page: this.pagination.page,
      };
      this.fetchVariantsStore(options)
        .then((data) => {
          const tmpPagination = { ...this.pagination };
          tmpPagination.total = data.paginatorInfo.total;
          tmpPagination.pageSize = data.paginatorInfo.perPage;
          tmpPagination.current = data.paginatorInfo.currentPage;
          this.pagination = tmpPagination;
          this.isFirst = false;
        })
        .catch((error) => {
          console.error("error fetch variants store: ", error);
        });
    },
    fetchStoresList() {
      const options = {
        orderBy: [{ column: "NAME", order: "ASC" }],
        is_active: true,
        is_member: true,
        first: 100,
        page: 1,
      };
      this.fetchStores(options)
        .then((resp) => {
          if (resp.data.length > 0) {
            // if (!localStorage.getItem("lastStoreId")) {
            //   localStorage.setItem("lastStoreId", resp.data[0].id);
            // }
            this.currentStoreId = resp.data[0].id;
            this.fetchData();
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        });
    },
    fetchStoresAndVariants() {
      const orderBy = this.sorter ? this.sorter : [{ column: "UPDATED_AT", order: "DESC" }];

      const options = {
        orderBy: [{ column: "NAME", order: "ASC" }],
        is_active: true,
        is_member: true,
        first: 100,
        page: 1,
        variantStoreId: this.currentStoreId,
        variantOrderBy: orderBy,
        variantSearchKeyword: this.search || null,
        variantFirst: this.pagination.perpage,
        variantPage: this.pagination.page,
      };
      this.fetchStoresVariants(options)
        .then((data) => {
          const tmpPagination = { ...this.pagination };
          tmpPagination.total = data.paginatorInfo.total;
          tmpPagination.pageSize = data.paginatorInfo.perPage;
          tmpPagination.current = data.paginatorInfo.currentPage;
          this.pagination = tmpPagination;
          this.isFirst = false;
        })
        .catch((error) => {
          console.error("error fetch variants store: ", error);
        });
    },
  },
  created() {
    this.$emit("setRightSidebarVisible", false);
  },
  mounted() {
    // if (localStorage.getItem("lastStoreId")) {
    //   this.currentStoreId = localStorage.getItem("lastStoreId");
    // }
    // if (this.currentStoreId) {
    //   this.fetchStoresAndVariants();
    // } else {
    //   this.fetchStoresList();
    // }
    this.fetchStoresList();
  },
});
</script>

<style lang="scss" scoped>
.header-button-add {
  padding: 14px 24px;
  border-radius: 26px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background: #0146ab;
  outline: none;

  .icon {
    font-size: 16px;
  }

  &:focus {
    background: #1f64b8;
  }

  &:active {
    background: #0146ab;
  }
}
</style>
