var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('div',[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{staticClass:"fixed-side-container",attrs:{"span":8}},[_c('div',{staticClass:"stockproduct-steps-container"},[_c('a-steps',{attrs:{"direction":"vertical","size":"small"},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('a-step',{attrs:{"title":"Varian Produk","description":"Pilih varian produk yang akan ditambahkan sebagai stok"}}),_c('a-step',{attrs:{"title":"Harga & Jumbah Stok","description":"Lengkapi informasi stok yang akan ditambahkan","disabled":_vm.formStep0}})],1)],1)]),(_vm.currentStep === 0)?[_c('a-col',{attrs:{"span":16}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"store-info-header"},[_c('div',{staticClass:"image-box"},[(_vm.clientLogo)?_c('div',[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":_vm.clientLogo}})]):_c('div',{style:(_vm.imgPreviewStyle)},[_c('a-icon',{staticStyle:{"font-size":"32px","color":"#b2b3b5"},attrs:{"type":"picture"}})],1)]),_c('div',{staticStyle:{"flex":"6"}},[_c('div',{staticClass:"store-name"},[_vm._v(_vm._s(this.getStore.name))]),_c('div',{staticClass:"store-address"},[_vm._v(_vm._s(this.getStore.address))])])]),_c('div',{staticClass:"stockproduct-step1-form-container"},[(_vm.sourceData.length > 0)?_c('a-transfer',{attrs:{"data-source":_vm.sourceData,"target-keys":_vm.targetKeys,"titles":['Daftar Varian', 'Varian Terpilih'],"render":function (item) { return item.title; },"list-style":{
                width: '200px',
              },"operations":['Tambah', 'Batal'],"show-search":true,"show-select-all":false,"filter-option":_vm.filterOption,"locale":{
                searchPlaceholder: 'Cari Varian'
              }},on:{"change":_vm.handleTransferChange},scopedSlots:_vm._u([{key:"children",fn:function(ref){
                var ref_props = ref.props;
                var direction = ref_props.direction;
                var filteredItems = ref_props.filteredItems;
                var selectedKeys = ref_props.selectedKeys;
                var listDisabled = ref_props.disabled;
                var ref_on = ref.on;
                var itemSelectAll = ref_on.itemSelectAll;
                var itemSelect = ref_on.itemSelect;
return [_c('a-table',{staticClass:"p-stock-product-transfer",style:({ pointerEvents: listDisabled ? 'none' : null }),attrs:{"row-selection":_vm.getRowSelection({
                      disabled: listDisabled,
                      selectedKeys: selectedKeys,
                      itemSelectAll: itemSelectAll,
                      itemSelect: itemSelect
                    }),"columns":direction === 'left' ? _vm.leftColumns : _vm.rightColumns,"data-source":filteredItems,"size":"small","loading":_vm.isTableLoading,"pagination":{ pageSize: 5 },"custom-row":function (ref) {
                                  var key = ref.key;
                                  var itemDisabled = ref.disabled;

                                  return ({
                      on: {
                        click: function () {
                          if (itemDisabled || listDisabled) { return; }
                          itemSelect(key, !selectedKeys.includes(key));
                        }
                      }
                    });
                }}})]}}],null,false,2315711780)}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"stockproduct-button-container"},[_c('a-button',{staticClass:"button-cancel",attrs:{"size":"large"},on:{"click":_vm.handleCancel}},[_vm._v(" Batal ")]),_c('a-button',{staticClass:"button-next",attrs:{"size":"large","type":"primary","disabled":_vm.formStep0},on:{"click":function($event){return _vm.changeStep(0)}}},[_vm._v(" Selanjutnya ")])],1)])]:(_vm.currentStep === 1)?[_c('a-col',{attrs:{"span":16}},[_c('div',{staticClass:"store-info-header"},[_c('div',{staticClass:"image-box"},[(_vm.clientLogo)?_c('div',[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":_vm.clientLogo}})]):_c('div',{style:(_vm.imgPreviewStyle)},[_c('a-icon',{staticStyle:{"font-size":"32px","color":"#b2b3b5"},attrs:{"type":"picture"}})],1)]),_c('div',{staticStyle:{"flex":"6"}},[_c('div',{staticClass:"store-name"},[_vm._v(_vm._s(this.getStore.name))]),_c('div',{staticClass:"store-address"},[_vm._v(_vm._s(this.getStore.address))])])]),_c('div',{staticClass:"stockproduct-step2-form-container"},[_c('a-form-model',{ref:"targetStockForm",attrs:{"hideRequiredMark":"","layout":"vertical","model":_vm.targetStockForm}},[_c('div',{staticClass:"stock-wrapper"},_vm._l((_vm.targetStockForm.targetStock),function(item,index){return _c('div',{key:item.id,staticClass:"stock-container"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"variant-name"},[_vm._v(_vm._s(item.name))])]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticClass:"label-field"},[_vm._v("Harga")]),_c('div',{staticStyle:{"display":"flex"}},[_c('a-form-model-item',{staticStyle:{"flex":"1"},attrs:{"prop":'targetStock.' + index + '.price',"rules":{
                            required: true,
                            message: 'Data tidak valid',
                            trigger: 'change'
                          }}},[_c('a-input',{attrs:{"size":"large","placeholder":"Misal: 1000"},on:{"change":function($event){return _vm.checkFormValid(1)},"keypress":function($event){return _vm.isDecimal($event, index)}},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}},[_c('span',{attrs:{"slot":"prefix"},slot:"prefix"},[_c('span',{staticClass:"label-field",staticStyle:{"padding-right":"8px","border-right":"1px solid #e5e5e6"}},[_vm._v(" Rp ")])])])],1)],1)]),_c('div',{staticStyle:{"width":"20px"}}),_c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticClass:"label-field"},[_vm._v("Jumlah Stok")]),_c('a-form-model-item',{attrs:{"prop":'targetStock.' + index + '.stock',"rules":{
                          required: true,
                          message: 'Data tidak valid',
                          trigger: 'change'
                        }}},[_c('a-input',{attrs:{"size":"large","placeholder":"Misal: 1000"},on:{"change":function($event){return _vm.checkFormValid(1)},"keypress":function($event){return _vm.isNumber($event, 'integer')}},model:{value:(item.stock),callback:function ($$v) {_vm.$set(item, "stock", $$v)},expression:"item.stock"}})],1)],1),_c('div',{staticStyle:{"width":"20px"}}),_c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticClass:"label-field"},[_vm._v("Status")]),_c('a-form-model-item',{attrs:{"prop":'targetStock.' + index + '.status',"rules":{
                          required: true,
                          message: 'Data tidak valid',
                          trigger: 'change'
                        }}},[_c('a-select',{staticClass:"p-select-status",class:{ 'active-status': item.status === 'Aktif' },attrs:{"size":"large"},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}},[_c('a-select-option',{attrs:{"value":"Aktif"}},[_vm._v(" Aktif ")]),_c('a-select-option',{attrs:{"value":"Tidak Aktif"}},[_vm._v(" Tidak Aktif ")])],1)],1)],1)])])}),0),_c('div',{staticClass:"stockproduct-button-container"},[_c('a-button',{staticClass:"button-cancel",attrs:{"size":"large"},on:{"click":function($event){return _vm.changePrevStep(1)}}},[_vm._v(" Kembali ")]),_c('a-button',{staticClass:"button-next",attrs:{"size":"large","type":"primary","disabled":_vm.formStep1,"loading":_vm.isLoadingUpdate},on:{"click":_vm.handleSave}},[_vm._v(" Tambah Stok ")])],1)])],1)])]:_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }