<template>
  <a-spin :spinning="isLoading">
    <div>
      <a-row :gutter="24">
        <a-col :span="8" class="fixed-side-container">
          <div class="stockproduct-steps-container">
            <a-steps v-model="currentStep" direction="vertical" size="small">
              <a-step
                title="Varian Produk"
                description="Pilih varian produk yang akan ditambahkan sebagai stok"
              />
              <a-step
                title="Harga & Jumbah Stok"
                description="Lengkapi informasi stok yang akan ditambahkan"
                :disabled="formStep0"
              />
            </a-steps>
          </div>
        </a-col>
        <template v-if="currentStep === 0">
          <a-col :span="16">
            <div class="store-info-header" v-show="!isLoading">
              <div class="image-box">
                <div v-if="clientLogo">
                  <img
                    :src="clientLogo"
                    style="width: 100%; height: auto"
                  />
                </div>
                <div v-else :style="imgPreviewStyle">
                  <a-icon style="font-size: 32px; color: #b2b3b5" type="picture" />
                </div>
              </div>
              <div style="flex: 6">
                <div class="store-name">{{ this.getStore.name }}</div>
                <div class="store-address">{{ this.getStore.address }}</div>
              </div>
            </div>
            <div class="stockproduct-step1-form-container">
              <a-transfer
                v-if="sourceData.length > 0"
                :data-source="sourceData"
                :target-keys="targetKeys"
                :titles="['Daftar Varian', 'Varian Terpilih']"
                :render="item => item.title"
                :list-style="{
                  width: '200px',
                }"
                :operations="['Tambah', 'Batal']"
                :show-search="true"
                :show-select-all="false"
                :filter-option="filterOption"
                :locale="{
                  searchPlaceholder: 'Cari Varian'
                }"
                @change="handleTransferChange"
              >
                <template
                  slot="children"
                  slot-scope="{
                    props: { direction, filteredItems, selectedKeys, disabled: listDisabled },
                    on: { itemSelectAll, itemSelect }
                  }"
                >
                  <a-table
                    class="p-stock-product-transfer"
                    :row-selection="
                      getRowSelection({
                        disabled: listDisabled,
                        selectedKeys,
                        itemSelectAll,
                        itemSelect
                      })
                    "
                    :columns="direction === 'left' ? leftColumns : rightColumns"
                    :data-source="filteredItems"
                    size="small"
                    :loading="isTableLoading"
                    :pagination="{ pageSize: 5 }"
                    :style="{ pointerEvents: listDisabled ? 'none' : null }"
                    :custom-row="
                      ({ key, disabled: itemDisabled }) => ({
                        on: {
                          click: () => {
                            if (itemDisabled || listDisabled) return;
                            itemSelect(key, !selectedKeys.includes(key));
                          }
                        }
                      })
                    "
                  />
                </template>
              </a-transfer>
            </div>
            <div class="stockproduct-button-container" v-show="!isLoading">
              <a-button size="large" class="button-cancel" @click="handleCancel">
                Batal
              </a-button>
              <a-button
                size="large"
                type="primary"
                class="button-next"
                :disabled="formStep0"
                @click="changeStep(0)"
              >
                Selanjutnya
              </a-button>
            </div>
          </a-col>
        </template>
        <template v-else-if="currentStep === 1">
          <a-col :span="16">
            <div class="store-info-header">
              <div class="image-box">
                <div v-if="clientLogo">
                  <img
                    :src="clientLogo"
                    style="width: 100%; height: auto;"
                  />
                </div>
                <div v-else :style="imgPreviewStyle">
                  <a-icon style="font-size: 32px; color: #b2b3b5" type="picture" />
                </div>
              </div>
              <div style="flex: 6">
                <div class="store-name">{{ this.getStore.name }}</div>
                <div class="store-address">{{ this.getStore.address }}</div>
              </div>
            </div>
            <div class="stockproduct-step2-form-container">
              <a-form-model
                hideRequiredMark
                layout="vertical"
                ref="targetStockForm"
                :model="targetStockForm"
              >
                <div class="stock-wrapper">
                  <div
                    v-for="(item, index) in targetStockForm.targetStock"
                    :key="item.id"
                    class="stock-container"
                  >
                    <div style="display: flex; justify-content: space-between">
                      <div class="variant-name">{{ item.name }}</div>
                    </div>
                    <div style="display: flex">
                      <div style="flex: 1">
                        <div class="label-field">Harga</div>
                        <div style="display: flex">
                          <a-form-model-item
                            :prop="'targetStock.' + index + '.price' "
                            :rules="{
                              required: true,
                              message: 'Data tidak valid',
                              trigger: 'change'
                            }"
                            style="flex: 1"
                          >
                            <a-input
                              v-model="item.price"
                              size="large"
                              placeholder="Misal: 1000"
                              @change="checkFormValid(1)"
                              @keypress="isDecimal($event, index)"
                            >
                              <span slot="prefix">
                                <span
                                  class="label-field"
                                  style="padding-right: 8px; border-right: 1px solid #e5e5e6;"
                                >
                                  Rp
                                </span>
                              </span>
                            </a-input>
                          </a-form-model-item>
                        </div>
                      </div>
                      <div style="width: 20px"></div>
                      <div style="flex: 1">
                        <div class="label-field">Jumlah Stok</div>
                        <a-form-model-item
                          :prop="'targetStock.' + index + '.stock' "
                          :rules="{
                            required: true,
                            message: 'Data tidak valid',
                            trigger: 'change'
                          }"
                        >
                          <a-input
                            v-model="item.stock"
                            size="large"
                            placeholder="Misal: 1000"
                            @change="checkFormValid(1)"
                            @keypress="isNumber($event, 'integer')"
                          />
                        </a-form-model-item>
                      </div>
                      <div style="width: 20px"></div>
                      <div style="flex: 1">
                        <div class="label-field">Status</div>
                        <a-form-model-item
                          :prop="'targetStock.' + index + '.status'"
                          :rules="{
                            required: true,
                            message: 'Data tidak valid',
                            trigger: 'change'
                          }"
                        >
                           <a-select
                            v-model="item.status"
                            class="p-select-status"
                            :class="{ 'active-status': item.status === 'Aktif' }"
                            size="large"
                          >
                            <a-select-option
                              value="Aktif"
                            >
                              Aktif
                            </a-select-option>
                            <a-select-option
                              value="Tidak Aktif"
                            >
                              Tidak Aktif
                            </a-select-option>
                           </a-select>
                        </a-form-model-item>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="stockproduct-button-container">
                  <a-button size="large" class="button-cancel" @click="changePrevStep(1)">
                    Kembali
                  </a-button>
                  <a-button
                    size="large"
                    type="primary"
                    class="button-next"
                    :disabled="formStep1"
                    :loading="isLoadingUpdate"
                    @click="handleSave"
                  >
                    Tambah Stok
                  </a-button>
                </div>
              </a-form-model>
            </div>
          </a-col>
        </template>
      </a-row>
    </div>
  </a-spin>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import difference from "lodash/difference";
import { message } from "ant-design-vue";

const leftTableColumns = [
  {
    dataIndex: "title",
    title: "Pilih Semua",
  },
];
const rightTableColumns = [
  {
    dataIndex: "title",
    title: "Pilih Semua",
  },
];

export default Vue.extend({
  data() {
    return {
      formLayout: "vertical",
      formStep0: false,
      formStep1: true,
      currentStep: 0,
      isLoading: false,
      isLoadingUpdate: false,
      sourceData: [],
      targetKeys: [],
      targetStockForm: {
        targetStock: [],
      },
      leftColumns: leftTableColumns,
      rightColumns: rightTableColumns,
      isTableLoading: false,
      imgPreviewStyle: {
        backgroundColor: "#e5e5e6",
        width: "100px",
        height: "65px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      clientLogo: JSON.parse(String(localStorage.getItem("userData"))).client.client_logo,
      previousPrice: null,
    };
  },
  props: {
    id: { type: String },
  },
  computed: {
    ...mapGetters("StockProductStore", ["getStore"]),
  },
  methods: {
    ...mapActions("UserStore", ["getUserData"]),
    ...mapActions("StockProductStore", ["fetchOneStore", "fetchStoreUnmappingVariant", "fetchUnmappingVariantsInStore", "createVariantStores"]),
    handleCancel() {
      this.$emit("handleCancel");
    },
    isNumber(event) {
      if (!/\d/.test(event.key)) {
        return event.preventDefault();
      }

      return true;
    },
    isDecimal(event, idx) {
      const keyCode = (event.keyCode ? event.keyCode : event.which);
      // only allow number and one dot
      if ((keyCode < 48 || keyCode > 57)
        && (keyCode !== 46 || this.targetStockForm.targetStock[idx].price.indexOf(".") !== -1)) { // 46 is dot
        return event.preventDefault();
      }

      // restrict to 2 decimal places
      if (this.targetStockForm.targetStock[idx].price !== ""
        && this.targetStockForm.targetStock[idx].price.indexOf(".") > -1
        && (this.targetStockForm.targetStock[idx].price.split(".")[1].length > 1)
        && (event.target.selectionStart > this.targetStockForm.targetStock[idx].price.indexOf("."))
      ) {
        return event.preventDefault();
      }

      return true;
    },
    checkFormValid(step) {
      if (step === 0) {
        if (this.targetStockForm.targetStock.length === 0) {
          this.formStep0 = true;
        } else {
          this.formStep0 = false;
        }
      } else if (step === 1) {
        this.formStep1 = this.targetStockForm.targetStock.some((variant) => variant.price === ""
          || variant.stock === "");
      }
    },
    changePrevStep(current) {
      if (current === 1) {
        this.currentStep = 0;
      }
    },
    changeStep(current) {
      if (current === 0) {
        this.checkFormValid(0);
        this.currentStep = 1;
      }
    },
    handleTransferChange(nextTargetKeys, direction, moveKeys) {
      this.targetKeys = nextTargetKeys;
      const newKeys = [];
      if (direction === "right") {
        moveKeys.forEach((key) => {
          const addKey = {
            id: key,
            name: this.sourceData.find((element) => element.id === key).name,
            price: "",
            stock: "",
            status: "Aktif",
          };
          newKeys.push(addKey);
        });

        this.targetStockForm.targetStock = newKeys.concat(this.targetStockForm.targetStock);
      } else if (direction === "left") {
        moveKeys.forEach((key) => {
          this.targetStockForm
            .targetStock = this.targetStockForm.targetStock.filter((target) => target.id !== key);
        });
      }
      this.checkFormValid(0);
    },
    filterOption(inputValue, item) {
      return item.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
    },
    getRowSelection({
      disabled, selectedKeys, itemSelectAll, itemSelect,
    }) {
      return {
        getCheckboxProps: (item) => ({
          props: { disabled: disabled || item.disabled },
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },
    handleOkMessage(msg) {
      this.isLoadingUpdate = false;
      message.success(msg);
      this.$emit("handleOk");
    },
    handleSave(e) {
      e.preventDefault();
      this.isLoadingUpdate = true;
      this.targetStockForm.targetStock.forEach((variant, idx) => {
        const payload = {
          storeId: this.id,
          variants: [
            {
              variant_id: variant.id,
              price: Number(variant.price),
              quantity: Number(variant.stock),
              is_active: variant.status === "Aktif",
            },
          ],
        };
        this.createVariantStores(payload)
          .then(() => {
            if (idx === this.targetStockForm.targetStock.length - 1) {
              this.handleOkMessage("Stok produk berhasil ditambah");
            }
          })
          .catch((error) => {
            console.error("error save stock variant : ", error);
            message.error(`Gagal menambahkan stok produck dari varian ${variant.name}, mohon ulangi beberapa saat lagi`);
            if (idx === this.targetStockForm.targetStock.length - 1) {
              this.isLoadingUpdate = false;
            }
          });
      });
    },
    async fetchUnmappingVariantsStore() {
      const options = {
        storeId: this.id,
        orderBy: [{ column: "NAME", order: "ASC" }],
        first: 50,
        page: 1,
      };
      const res = await this.fetchStoreUnmappingVariant(options);
      let pg = 1;
      const totalPage = res.paginatorInfo.total;
      const fetchAll = [];
      fetchAll.push(res.data);
      for (let i = 50; i < totalPage; i += 50) {
        pg += 1;
        const payld = {
          storeId: this.id,
          orderBy: [{ column: "NAME", order: "ASC" }],
          first: 50,
          page: pg,
        };
        fetchAll.push(this.fetchUnmappingVariantsInStore(payld)
          .then((resp) => resp.data));
      }
      const allResp = await Promise.all(fetchAll);
      const allStores = allResp.flat(1);
      const tmp = allStores;
      const stores = tmp.map((item) => {
        const itm = { ...item };
        itm.key = itm.id;
        itm.title = itm.name;
        return itm;
      });
      this.sourceData = stores;
      this.checkFormValid(0);
      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getUserData()
      .then(() => {
        this.clientLogo = JSON.parse(String(localStorage.getItem("userData"))).client.client_logo;
      });
    this.fetchUnmappingVariantsStore();
  },
});
</script>

<style lang="scss">
.ant-transfer-operation .ant-btn {
  width: 86.66px;
}

.p-stock-product-transfer {
  .ant-table colgroup > col.ant-table-selection-col {
    width: 40px;
  }
}

.stock-container {
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 46px;
  }
}

.p-select-status.ant-select-lg {
  width: 138px;
  color: #ff6e5d;

  .ant-select-selection--single {
    border-radius: 24px;
    border: 1px solid #ff6e5d;
    background: rgba(255, 226, 222, 0.6);
  }
}

.p-select-status.active-status.ant-select-lg {
  width: 138px;
  color:  #0146ab;

  .ant-select-selection--single {
    border-radius: 24px;
    border: 1px solid #0146ab;
    background: rgba(204, 218, 238, 0.6);
  }
}
</style>

<style lang="scss" scoped>
.label-field {
  color: #4c4f54;
  font-size: 14px;
  font-weight: 700;
}

.store-info-header {
  display: flex;
  border: solid 1px #e5e5e6;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;

  .image-box {
    margin-right: 24px;
    display: flex;
    align-items: center;
    flex: 1;
  }

  .store-name {
    color: #0146ab;
    font-size: 18px;
    font-weight: 700;
  }

  .store-address {
    color: #4c4f54;
    font-size: 16px;
  }
}

.stockproduct-step0-form-container {
  border-radius: 16px;
  border: 1px solid #e5e5e6;
  padding: 16px;
  margin-bottom: 16px;

  .store-name {
    color: #0046ab;
    font-size: 16px;
    font-weight: 700;
  }
}

.stockproduct-button-container {
  margin-top: 40px;

  .button-cancel {
    width: 47.5%;
    margin-right: 16px;
    border-radius: 26px;
    font-weight: 700;
    color: #0146ab;
  }

  .button-next {
    width: 47.5%;
    border-radius: 26px;
    font-weight: 700;
  }
}

.stock-container {
  border: solid 1px #e5e5e6;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;

  .variant-name {
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    color: #0146ab;
    display: flex;
    align-items: center;
  }
}
</style>
