<template>
  <div>
    <a-modal
      :visible="visible"
      centered
      :width="widthComputed"
      :footer="null"
      :maskClosable="false"
      :closable="true"
      :destroyOnClose="visibleContent !== 'Filter'"
      :style="{ borderRadius: '24px' }"
      @ok="handleOk"
      @cancel="handleCancel"
      class="p-stockproduct-modal"
    >
      <template slot="title">
        <div>
          <span class="p-modal-header-title">
            {{ modalTitle }}
          </span>
        </div>
      </template>
      <template v-if="visibleContent === ''">
        <div :style="'display: block;'">
          <stock-product-form
            :id="storeId"
            @handleOk="handleOk"
            @handleCancel="handleCancel"
          />
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import Vue from "vue";
import StockProductForm from "./StockProductForm.vue";

export default Vue.extend({
  data() {
    return {
      visible: false,
      visibleContent: "",
      storeId: "",
    };
  },
  props: {
    modalTitle: {
      type: String,
    },
  },
  components: {
    StockProductForm,
  },
  computed: {
    widthComputed() {
      if (this.visibleContent === "Delete") {
        return 400;
      }

      return 950;
    },
  },
  methods: {
    showModal(id) {
      this.visible = true;
      this.visibleContent = "";
      this.storeId = id;
    },
    handleOk() {
      this.visible = false;
      this.$emit("fetchData");
    },
    handleCancel() {
      this.visible = false;
    },
  },
});
</script>

<style lang="scss">
.p-stockproduct-modal {
  .ant-modal .ant-modal-content .ant-modal-body {
    max-height: 672px;
  }
}
</style>
